import React from "react"
import Instagram from "../images/instagram.inline.svg"
import Twitter from "../images/twitter.inline.svg"
import Linkedin from "../images/linkedin.inline.svg"
import Github from "../images/github.inline.svg"

const IndexPage = () => (
  <div className="flex justify-center h-screen bg-gray-900 items-center">
    <div className="text-center text-white">
      <h1 className="text-3xl">Hi, welcome! I'm Yocsel Mendoza</h1>
      <div>
        <img
          src="https://res.cloudinary.com/da2p3ngcv/image/upload/f_auto,w_192,q_auto:best/v1679685202/avatar_m0czfn.jpg"
          className="h-48 w-48 bg-white rounded-full inline-block mt-4 mb-4 shadow-sm border-4 border-solid border-gray-800"
        />
      </div>
      <h3 className="text-xl">Software Engineer</h3>

      <div className="flex justify-center mt-4">
        <a
          href="https://twitter.com/y0cmen"
          target="_blank"
          style={{ marginRight: 10 }}
        >
          <Twitter
            style={{ fill: "white", width: "1.5rem" }}
            className="icon"
          />
        </a>
        <a
          href="https://www.instagram.com/yocmen/"
          target="_blank"
          style={{ marginRight: 10 }}
        >
          <Instagram
            style={{ fill: "white", width: "1.5rem" }}
            className="icon"
          />
        </a>
        <a
          href="https://www.github.com/yocmen/"
          target="_blank"
          style={{ marginRight: 10 }}
        >
          <Github style={{ fill: "white", width: "1.5rem" }} className="icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/yocmen"
          target="_blank"
          style={{ marginRight: 10 }}
        >
          <Linkedin
            style={{ fill: "white", width: "1.5rem" }}
            className="icon"
          />
        </a>
      </div>
    </div>
  </div>
)

export default IndexPage
